import React from "react"
import {Link} from "gatsby"
import QuoteForm from "../partials/quote-form";
import Image from "../elements/image";
import Usps from "../partials/usps";
import AprText from "../partials/apr-text";

class SearchResults extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: false}
    }

    componentDidMount() {
        const self = this
        this.setState({loading: true})
        setTimeout(function () {
            self.setState({loading: false})
        }, 2000)
    }

    render() {
        return (
            <div className="section-container">
                {!this.props.loanData ? (
                    <div className="section page-banner page-banner-lg section-form">
                        <div className="container">
                            <div className="row">
                                <div className="col col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                                    <h1 className="h4">Borrow £100 - £5,000</h1>
                                    <p className="lead">Over 36 months</p>
                                    <QuoteForm/>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="section section-form section-form-lenders">
                        <div className="container">
                            <div className="row">
                                <div className="col col-sm-10 col-sm-offset-1 col-lg-8 col-lg-offset-2">
                                    {this.state.loading ? (
                                        <div className="lenders-loading">
                                            <Usps/>
                                            <div className="section-form-title">
                                                <h4>Loading lenders who will provide your loan</h4>
                                                <p className="lead">This will only take a moment</p>
                                            </div>
                                            <div className="loading-icon">
                                                <span className="loading-icon-ring"></span>
                                                <span className="loading-icon-ring"></span>
                                                <span className="loading-icon-ring"></span>
                                                <span className="loading-icon-ring"></span>
                                                <div className="loading-icon-dot"></div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="lenders-loaded">
                                                <div className="section-form-title">
                                                    <h4>Done! We've found lenders for you</h4>
                                                    <p className="lead">Now get your personalised rates</p>
                                                </div>
                                                <Link to='/apply/' state={this.props.loanData}
                                                      className='btn btn-default'>
                                                    Next Step : Get <br/>Personalised Quotes
                                                </Link>
                                            </div>
                                            <div className="lenders">
                                                <div className="box box-border box-lender">
                                                    <div className="lender-logo">
                                                        <div className="box box-lender-logo">
                                                            <Image img={require('../../images/logo-sainsburys.png')}/>
                                                        </div>
                                                        <p><strong>Personal Loan</strong>Representative<br/> Example</p>
                                                    </div>
                                                    <div className="lender-details">
                                                        <div className="lender-title">
                                                            <div className="lender-title-inner">
                                                                <h6>Sainsbury's Bank</h6>
                                                                <p className="info-text">
                                                                    <i className="far fa-info-circle"></i> More
                                                                    information
                                                                    needed
                                                                    to get rate
                                                                </p>
                                                            </div>
                                                            <div className="lender-title-btn">
                                                                <Link to='/apply/' state={this.props.loanData}
                                                                      className='btn btn-sm btn-default'>Get Rates</Link>
                                                            </div>
                                                        </div>
                                                        <div className="loan-details">
                                                            <div className="loan-detail"><i
                                                                className="fas fa-lock"></i><span>Rate</span></div>
                                                            <div className="loan-detail"><i
                                                                className="fas fa-lock"></i><span>Monthly</span></div>
                                                            <div className="loan-detail"><i
                                                                className="fas fa-lock"></i><span>Certainty</span></div>
                                                            <div className="loan-detail"><i
                                                                className="fas fa-lock"></i><span>Repayment</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default SearchResults