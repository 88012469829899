import React from "react"
import Layout from "../layouts/index"
import SEO from "../components/seo"
import SearchResults from "../components/section/search-results";
import withLoanData from "../components/helpers/withLoanData";

class Search extends React.Component {

    render() {
        const loanData = this.props.getLoanData()
        return (
            <Layout footer="min" location={this.props.location} reRender={true}>
                <SEO title="Search for a Loan" bodyAttributes={{class: 'quote-page'}}/>
                <SearchResults path="/search/" key={this.props.location.key} loanData={loanData}/>
            </Layout>
        )
    }
}

const SearchPage = withLoanData(Search)

export default SearchPage